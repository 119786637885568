import React from 'react';
import './Subscribe.css';

const Subscribe = () => {
    return (
        <div className="newslater-section">
            <div className="container">
                <div
                    className="newslater-container"
                >
                    <div className="newslater-wrapper">
                        <h5 className="cate">subscribe to NammaChitra</h5>
                        <h3 className="title">to get exclusive benefits</h3>
                        <form className="newslater-form">
                            <input type="text" placeholder="Your Email Address" />
                            <button type="submit">subscribe</button>
                        </form>
                        <p>We respect your privacy, so we never share your info</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Subscribe;
