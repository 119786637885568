import React from 'react';
import './Banner.css';

const Banner = () => {
    return (
        <section className="banner-section">
            <div className="banner-bg"></div>

            <div className="container">
                <div className="banner-content">
                    <h1 className="title">
                        BOOK YOUR <br /> TICKETS FOR <br />
                        <span className="highlight"> CELEBRITY BADMINTON CUP</span>
                    </h1>
                </div>
            </div>
        </section>
    );
};

export default Banner;
