import React from 'react';
import './Footer.css';
import footerlogo from '../images/NammaChitraLogoBgR.png';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-container">
                {/* Footer Logo Section */}
                <div className="footer-logo">
                    <a href="/">
                        <img src={footerlogo} alt="Logo" className="footer-logo-image" />
                    </a>
                </div>

                <div className="footer-bottom">
                    <p className="footer-text">
                        Copyright © 2024. All Rights Reserved By{' '}
                        <a href="#" className="footer-link">
                            NammaChitra
                        </a>
                    </p>
                    <div className="footer-links">
                        <a href="#" className="footer-link">About</a>
                        <a href="#" className="footer-link">Terms Of Use</a>
                        <a href="#" className="footer-link">Privacy Policy</a>
                        <a href="#" className="footer-link">FAQ</a>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;

