
import React from 'react';
import './App.css';  // You can keep this for global styles
import Menu from './Components/menu';
import Banner from './Components/Banner';
import Footer from './Components/Footer';
import Subscribe from './Components/Subscribe';

function App() {
  return (
    <div className="App">
      <Menu />
      <Banner />
      <Subscribe />
      <Footer />
    </div>
  );
}

export default App;
