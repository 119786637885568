
import React from 'react';
import './menu.css';
import logo from '../images/NammaChitraLogoBgR.png'; // Correct path for logo import

const Menu = () => {
    return (
        <header className="navbar">
            <div className="navbar-container">

                <div className="logo">
                    <img src={logo} alt="Logo" />
                </div>

            </div>
        </header>
    );
};

export default Menu;

